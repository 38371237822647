import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SectionAdmin from 'atoms/sectionadmin/SectionAdmin';
import Button from 'atoms/button/Button';
import DataSourceSelect from 'components/admin/datasourceselect/DataSourceSelect';
import { useAuth } from 'services/UseAuth';
import ApiService from 'services/ApiService';
import Loader from 'atoms/loader/Loader';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import styles from './LoadConfiguration.module.css';

function LoadConfiguration({
  breakbys,
  dataSources,
  dsl1,
  dsl2,
  filters,
  isSaving,
  previousYear,
  setBreakbys,
  setFilters,
  toastMessage,
  year,
  role,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadConfig, setLoadConfig] = useState(false);

  const [copyYear, setCopyYear] = useState(previousYear || year);
  const [copyDsl1, setCopyDsl1] = useState(dsl1);
  const [copyDsl2, setCopyDsl2] = useState(dsl2);
  const [copyRole, setCopyRole] = useState(role);

  const {
    user: { forbund, token },
  } = useAuth();

  // Reduce an array into an object
  // [ { sel_var: "ARBSEK", filterOrder: 0, isActive: true } ] => { "ARBSEK": { filterOrder: 0, isActive: true } }
  const createObject = ({ data, key, identificator, orderBy, sortBy }) =>
    data[key].reduce((acc, curr) => {
      if (!acc[curr[identificator]]) {
        acc[curr[identificator]] = {
          [orderBy]: curr[orderBy],
          [sortBy]: curr[sortBy],
          isActive: curr.isActive,
        };
      }

      return acc;
    }, {});

  // Update array with copied data
  const updateArray = ({ data, object, identificator, orderBy, sortBy }) => {
    let count = 0;
    const updatedData = data.map(item => {
      const tempItem = { ...item };

      if (object[tempItem[identificator]]) {
        tempItem[orderBy] = object[tempItem[identificator]][orderBy];
        tempItem[sortBy] = object[tempItem[identificator]][sortBy];
        tempItem.isActive = object[tempItem[identificator]].isActive;

        count += 1;
      }

      return tempItem;
    });

    return [updatedData, count];
  };

  useEffect(() => {
    if (!loadConfig) return;
    if (
      dataSources == null ||
      !dataSources.some(
        ds =>
          ds.YEAR === copyYear && ds.DSL1 === copyDsl1 && ds.DSL2 === copyDsl2
      ) ||
      isLoading
    )
      return;
    setIsLoading(true);

    const request = {
      forbund,
      year: copyYear,
      dsl1: copyDsl1,
      dsl2: copyDsl2,
      role,
    };

    ApiService.getPresentations(request, token)
      .then(data => {
        const breakbyObject = createObject({
          data,
          key: 'breakbys',
          identificator: 'breakbyId',
          orderBy: 'breakbyOrder',
          sortBy: 'sortOptionsByLetters',
        });

        const filterObject = createObject({
          data,
          key: 'filters',
          identificator: 'selectionId',
          orderBy: 'filterOrder',
          sortBy: 'sortOptionsByNumber',
        });

        const [updatedBreakbys, breakbyCount] = updateArray({
          data: breakbys,
          object: breakbyObject,
          identificator: 'breakbyId',
          orderBy: 'breakbyOrder',
          sortBy: 'sortOptionsByLetters',
        });

        setBreakbys(updatedBreakbys);

        const [updatedFilters, filterCount] = updateArray({
          data: filters,
          object: filterObject,
          identificator: 'selectionId',
          orderBy: 'filterOrder',
          sortBy: 'sortOptionsByNumber',
        });

        setFilters(updatedFilters);

        if (breakbyCount > 0 || filterCount > 0) {
          toastMessage(
            'info',
            `Kopieringen av konfigurationen lyckades, hittade ${breakbyCount} matchande presentationssätt och 
            ${filterCount} matchande filter. Spara för att godkänna konfigurationen.`
          );
        } else {
          toastMessage(
            'warning',
            `Försökte utföra kopiering av konfiguration, men datakällorna innehåller inga matchande presentationssätt 
            eller filter.`
          );
        }
      })
      .catch(() => {
        toastMessage('error', 'Kopiering av konfiguration misslyckad');
      })
      .finally(() => {
        setIsLoading(false);
        setLoadConfig(false);
      });
    // eslint-disable-next-line
  }, [loadConfig]);

  return (
    <SectionAdmin title="Ladda in konfiguration">
      <div className={styles.container}>
        {isLoading && (
          <div className={styles.isLoading}>
            <Loader variant="primary" />
          </div>
        )}
        <p>Välj en annan statistikkälla att kopiera konfiguration från.</p>
        <DataSourceSelect
          dataSources={dataSources}
          year={copyYear}
          setYear={setCopyYear}
          dsl1={copyDsl1}
          setDsl1={setCopyDsl1}
          dsl2={copyDsl2}
          setDsl2={setCopyDsl2}
          role={copyRole}
          setRole={setCopyRole}
        />
        <ButtonBar marginBottom>
          <Button
            onClick={() => setLoadConfig(true)}
            variant="primary"
            label="Ladda in konfiguration"
            altText="Ladda in konfiguration"
            disabled={isSaving || isLoading}
          />
        </ButtonBar>
      </div>
    </SectionAdmin>
  );
}

LoadConfiguration.propTypes = {
  breakbys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataSources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dsl1: PropTypes.string.isRequired,
  dsl2: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSaving: PropTypes.bool.isRequired,
  previousYear: PropTypes.number,
  setBreakbys: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  toastMessage: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
};

LoadConfiguration.defaultProps = {
  previousYear: null,
};

export default LoadConfiguration;
