import AppSettings from '../appsettings.json';

const Api = () => {
  const BASE_URL = AppSettings.SERVER_URL;

  // function handleNetworkError(res) {
  //   // console.log('res');
  //   // console.log(res);
  //   if (!res.ok) {
  //     // console.log('res.ok1');
  //     // console.log(res.ok);
  //     throw res;
  //   }
  //   // console.log('res.ok2');
  //   // console.log(res.ok);
  //   return res;
  // }

  function handleNetworkError(res) {
    if (!res.ok) {
      return res.json().then(err => {
        // Hantera serverfel (500 och uppåt)
        if (res.status >= 500) {
          throw new Error(err.error || `Serverfel: ${res.statusText}`);
        }
        // Logga mindre allvarliga fel (4xx) och returnera ändå
        console.warn('Mindre fel upptäckt:', err.error || res.statusText);
        return res; // Returnera för att fortsatt hantera mindre fel.
      });
    }
    return res;
  }
  

  // TODO: Handle network errors
  // function handleNoContent(res) {
  //   if (res.status === 204) {
  //     throw res;
  //   }

  //   return res;
  // }

  function createGetRequest(page, token) {
    const req = {
      method: 'GET',
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    };

    return fetch(`${BASE_URL}/${page}`, req)
      .then(res => handleNetworkError(res))
      .then(res => res.json());
  }

  function createGetRequestWithCredentials(page, token) {
    const req = {
      method: 'GET',
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
      credentials: 'include',
    };

    return fetch(`${BASE_URL}/${page}`, req)
      .then(res => handleNetworkError(res))
      .then(res => res.json());
  }

  function createDeleteRequest(page, token) {
    const req = {
      method: 'DELETE',
      headers: !token
        ? { 'Content-type': 'application/json' }
        : {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
    };

    return fetch(`${BASE_URL}/${page}`, req)
      .then(res => handleNetworkError(res))
      .then(res => res.json());
  }

  function createPostRequest(page, reqData, token, returnJson = true) {
    const req = {
      method: 'POST',
      headers: !token
        ? { 'Content-type': 'application/json' }
        : {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
      body: JSON.stringify(reqData),
    };

    return fetch(`${BASE_URL}/${page}`, req)
      .then(res => handleNetworkError(res))
      .then(res => (returnJson ? res.json() : res));
  }

  function createPostRequestWithCredentials(
    page,
    reqData,
    token,
    returnJson = true
  ) {
    const req = {
      method: 'POST',
      headers: !token
        ? { 'Content-type': 'application/json' }
        : {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
      body: JSON.stringify(reqData),
    };

    return (
      fetch(`${BASE_URL}/${page}`, req)
        .then(res => handleNetworkError(res))
        // TODO: Handle network errors
        // .then(res => handleNoContent(res))
        .then(res => (returnJson ? res.json() : res))
    );
  }

  function createEventSource(page, messageEvent) {
    const eventSource = new EventSource(`${BASE_URL}/${page}`);
    eventSource.onmessage = e => messageEvent(e.data);
  }

  function createPostRequestWithToken(page, reqData, returnJson = true) {
    const { token } = reqData;
    const req = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    };

    return fetch(`${BASE_URL}/${page}`, req)
      .then(res => handleNetworkError(res))
      .then(res => res.json());
  }
  // API Endpoints:
  // forbund (get), fds, menuselection, metatabs, breakby, report.

  // DevLogin/AdminLogin
  return {
    login: data => createPostRequest('Token', data, false),
    testLogin: data => createPostRequest('Token/login', data),
    tokenLogin: data => createPostRequestWithToken('Token/session', data),
    forbundsKodLogin: data => createPostRequest('Token/forbund/session', data),

    // Getters
    getForbund: token =>
      createGetRequest('UnionSalaryStatistics/forbund', token),

    // Posts
    getFds: (data, token) =>
      createPostRequest('UnionSalaryStatistics/fds', data, token),

    getConfiguration: (data, token) =>
      createPostRequest('CopyConfiguration/config', data, token),
    copyConfigurationExecute: (data, token) =>
      createPostRequest('CopyConfigurationJob/execute', data, token, false),
    getActiveFds: (data, token) =>
      createPostRequest('Presentation/fds', data, token),
    getMenuselections: (data, token) =>
      createPostRequest('UnionSalaryStatistics/menuselection', data, token),
    getPreviewMenuSelection: data =>
      createPostRequest('UnionSalaryStatistics/menuselection/preview', data),
    getPreviewMenuSelections: data =>
      createPostRequest('UnionSalaryStatistics/menuselections/preview', data),
    getMetatabs: (data, token) =>
      createPostRequest('UnionSalaryStatistics/metatabs', data, token),
    getBreakby: (data, token) =>
      createPostRequest('UnionSalaryStatistics/breakby', data, token),
    getReport: (data, token) =>
      createPostRequest('UnionSalaryStatistics/lonereport', data, token),

    getBinstat: data =>
      createPostRequest('UnionSalaryStatistics/binstat', data),

    getReportExcel: (data, token) =>
      createPostRequest('File/excel', data, token, false),

    getUnemploymentStaticExcel: () =>
      createPostRequest('Unemployment/excel', null, null, false),

    getUnemploymentData: () => createPostRequest('Unemployment/data', null, null, true),

    sendVistitorStats: (data, token) =>
      createPostRequest('Statistic', data, token),

    // Admin/New endpoints
    // FIXME: Måste få bättre koll.
    getSituations: token => createGetRequest('Situation', token),
    getPreviewSituations: token =>
      createGetRequest('Situation/getPreviewSituations', token),
    createSituation: (data, token) =>
      createPostRequest('Situation', data, token),
    updateSituation: (id, data, token) =>
      createPostRequest(`Situation/${id}`, data, token),
    deleteSituation: (id, token) =>
      createDeleteRequest(`Situation/${id}`, token),
    updateSelections: (id, data, token) =>
      createPostRequest(`Situation/${id}/selections`, data, token),
    postDatasource: (id, data, token) =>
      createPostRequest(`DataSource/${id}`, data, token),
    getUnionByUnionId: unionId => createPostRequest(`Union/${unionId}`),
    getUnionByForbundsKod: forbundsKod =>
      createPostRequest(`Union/forbundskod/${forbundsKod}`),

    getUnion: token => createGetRequest('Union', token),
    getUnions: token => createGetRequest('Union/getall', token),
    refreshAdminToken: (data, token) =>
      createPostRequest('Token/adminrefresh', data, token),
    postConfiguredYear: (unionId, data, token) =>
      createPostRequest(`Union/${unionId}/configuration`, data, token, false),

    /**
     * getAll: get all texts of groupId and textId. Texts with unionId take precedence.
     * both: get all texts, both with unionId and default texts.
     */
    getTexts: (data, token) => createPostRequest('Text/getall', data, token),
    getTextsBoth: (data, token) => createPostRequest('Text/both', data, token),
    postText: (data, token) => createPostRequest('Text', data, token),
    deleteText: (id, token) => createDeleteRequest(`Text/${id}`, token),

    getLogs: (data, token) => createPostRequest('Log/getall', data, token),
    getMonitoringLogs: (data, token) =>
      createPostRequest('MonitoringLog/getall', data, token),
    getMonitoringLogsById: (data, token) =>
      createPostRequest('MonitoringLog/getbyid', data, token),
    getVisitorStats: (data, token) =>
      createPostRequest('Statistic/getall', data, token),
    getVisitorStatsNew: (data, token) =>
      createPostRequest('Statistic/getPeriodsStatistics', data, token),
    getUserStatistics: (data, token) =>
      createPostRequest('Statistic/getUserStatistics', data, token),
    getVisitorStatsByUnion: (data, token) =>
      createPostRequest('Statistic/sessionsbyforbund', data, token),
    getVisitorExcel: (data, token) =>
      createPostRequest('Statistic/excel', data, token, false),

    clearCache: token =>
      createPostRequest('UnionSalaryStatistics/clearcache', {}, token, false),

    // Nivå 1 endpoints.
    getSituationsFree: () => createGetRequest('Situation/public'),
    getReportFree: data =>
      createPostRequestWithCredentials(
        'UnionSalaryStatistics/lonereport/public',
        data
      ),
    getMenuselectionsFree: data =>
      createPostRequestWithCredentials(
        'UnionSalaryStatistics/menuselection/public',
        data
      ),

    createEventSource: (page, messageEvent) => {
      const eventSource = new EventSource(`${BASE_URL}/${page}`);
      eventSource.onmessage = e => messageEvent(e.data);
    },

    // Kontohantering admin.
    getSubscribers: token => createGetRequest('Subscriber', token),
    postSubscriber: (data, token) =>
      createPostRequest('Subscriber', data, token),
    updateAccount: (subId, data, token) =>
      createPostRequest(`Subscriber/${subId}/Roles`, data, token, false),
    updatePassword: (subId, data, token) =>
      createPostRequest(`Subscriber/${subId}/Password`, data, token, false),
    deleteSubscriber: (id, token) =>
      createDeleteRequest(`Subscriber/${id}`, token),
    // Nivå 3 config.
    getPresentations: (data, token) =>
      createPostRequest('Presentation/getall', data, token),
    postPresentations: (data, token) =>
      createPostRequest('Presentation/breakbys', data, token),
  };
};

const ApiService = Api();
export default ApiService;
