/* eslint-disable camelcase */
import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Loader from 'atoms/loader/Loader';
import SectionAdmin from 'atoms/sectionadmin/SectionAdmin';
import Tab from 'atoms/tab/Tab';
import TabRow from 'atoms/tabrow/TabRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import CheckboxAdmin from 'atoms/checkboxadmin/CheckboxAdmin';
import DataSourceSelect from '../../datasourceselect/DataSourceSelect';
import SortableList from './sortablelist/SortableList';
import styles from './DataSourceConfig.module.css';
import LoadConfiguration from './loadconfiguration/LoadConfiguration';

const DataSourceConfig = ({
  dataSources,
  toastMessage,
  isSaving,
  setIsSaving,
}) => {
  const [activeTab, setActiveTab] = useState('filter');

  const [dsl1, setDsl1] = useState('');
  const [dsl2, setDsl2] = useState('');
  const [year, setYear] = useState();
  const [role, setRole] = useState('medlem');

  const [isActive, setIsActive] = useState(true);
  const [breakbys, setBreakbys] = useState([]);
  const [filters, setFilters] = useState([]);

  const [loadConfiguration, setLoadConfiguration] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    user: { forbund, token },
  } = useAuth();

  // Sort the array, set the order of each item
  const sortData = (data, orderParameter) =>
    data
      .sort(a => (a[orderParameter] == null ? 1 : -1))
      .sort((a, b) => a[orderParameter] - b[orderParameter])
      .sort((a, b) => {
        if (a.isActive === b.isActive) return 0;
        if (a.isActive) return -1;
        return 1;
      })
      .map((item, index) => {
        const tempItem = { ...item };
        tempItem[orderParameter] = index;
        return tempItem;
      });

  const handleSubmit = () => {
    if (isSaving) return;

    setIsSaving(true);

    const request = {
      forbund,
      dsl1,
      dsl2,
      year,
      isActive,
      breakbys,
      filters,
      role,
    };

    ApiService.postPresentations(request, token)
    .then(updatedData => {
      // Uppdatera lokal state med responsen från backend
      setBreakbys(sortData(updatedData.breakbys, 'breakbyOrder'));
      setFilters(sortData(updatedData.filters, 'filterOrder'));
      setIsActive(updatedData.isActive);

      toastMessage(
        'success',
        'Nivå 3 presentations och filtervariabler sparade.'
      );
    })
    .catch(() =>
      toastMessage('error', 'Ett fel uppstod vid sparning av data.')
    )
    .finally(() => setIsSaving(false));

  };

  useEffect(() => {
    if (isLoading) return;
    if (
      dataSources == null ||
      !dataSources.some(
        ds => ds.YEAR === year && ds.DSL1 === dsl1 && ds.DSL2 === dsl2
      )
    ) {
      setIsActive(true);
      setBreakbys([]);
      setFilters([]);
      return;
    }

    setIsLoading(true);

    ApiService.getPresentations(
      {
        forbund,
        year,
        dsl1,
        dsl2,
        role,
      },
      token
    )
      .then(data => {
        setBreakbys(sortData(data.breakbys, 'breakbyOrder'));
        setFilters(sortData(data.filters, 'filterOrder'));
        setIsActive(data.isActive);

        console.log('----------------------------');
        console.log(`data.isFromDb: ${data.isFromDb}`);
        console.log("data.breakbys:", data.breakbys);
        console.log("data.filters:", data.filters);
        console.log('----------------------------');

      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [year, dsl1, dsl2, role]);

  return (
    <>
      <SectionAdmin title="Konfigurera statistikkälla">
        <div className={styles.sourceSelectContainer}>
          <p>
            Varje datakälla som du väljer kan ha sin egen uppsättning filter
            (urval) och presentationssätt.
          </p>
          <DataSourceSelect
            dataSources={dataSources}
            year={year}
            setYear={setYear}
            dsl1={dsl1}
            setDsl1={setDsl1}
            dsl2={dsl2}
            setDsl2={setDsl2}
            role={role}
            setRole={setRole}
          />
        </div>

        {isLoading && (
          <div className={styles.loaderContainer}>
            <Loader variant="primary" />
          </div>
        )}

        {(breakbys.length > 0 || filters.length > 0) && !isLoading && (
          <>
            <CheckboxAdmin
              checked={isActive}
              disabled={isLoading || isSaving}
              id="isActive"
              label="Aktiv"
              onChange={() => setIsActive(prevState => !prevState)}
            />
            <TabRow className={styles.tabRow}>
              <Tab
                active={activeTab === 'filter'}
                label="Filter (Urval)"
                onClick={() => setActiveTab('filter')}
              />
              <Tab
                active={activeTab === 'presentation'}
                label="Presentationssätt"
                onClick={() => setActiveTab('presentation')}
              />
            </TabRow>
            <div
              className={activeTab === 'filter' ? styles.shown : styles.hidden}
            >
              <SortableList
                data={filters}
                isSaving={isSaving}
                updateFunction={setFilters}
                variant="filter"
              />
            </div>

            <div
              className={
                activeTab === 'presentation' ? styles.shown : styles.hidden
              }
            >
              <SortableList
                data={breakbys}
                isSaving={isSaving}
                updateFunction={setBreakbys}
                variant="breakby"
              />
            </div>
            <ButtonBar marginBottom>
              <Button
                icon="Copy"
                color="#99d2d9"
                onClick={() => setLoadConfiguration(true)}
                variant="secondary"
                label="Ladda in konfiguration"
                altText="Ladda in konfiguration från annan källa"
                disabled={isSaving}
              />
              <Button
                color="#99d2d9"
                onClick={() => handleSubmit(isSaving)}
                variant="primary"
                label="Spara"
                altText="Spara löneprofil"
                disabled={isSaving}
              />
            </ButtonBar>
          </>
        )}
      </SectionAdmin>
      {loadConfiguration && (
        <LoadConfiguration
          breakbys={breakbys}
          dataSources={dataSources}
          dsl1={dsl1}
          dsl2={dsl2}
          filters={filters}
          isSaving={isSaving}
          setBreakbys={setBreakbys}
          setFilters={setFilters}
          toastMessage={toastMessage}
          year={year}
          role={role}
          setRole={setRole}
          previousYear={
            dataSources
              .filter(ds => ds.DSL1 === dsl1 && ds.DSL2 === dsl2)
              .sort((a, b) => b.YEAR - a.YEAR)
              .find(ds => ds.YEAR < year)?.YEAR
          }
        />
      )}
    </>
  );
};

DataSourceConfig.propTypes = {
  dataSources: PropTypes.arrayOf(Object),
  toastMessage: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  setIsSaving: PropTypes.func.isRequired,
};

DataSourceConfig.defaultProps = {
  dataSources: null,
};

export default DataSourceConfig;
