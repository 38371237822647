import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IconDatabase } from 'tabler-icons';
import Select from 'atoms/select/Select';
import { useAuth } from 'services/UseAuth';
import styles from './DataSource.module.css';
import DataSourceSummary from './datasourcesummary/DataSourceSummary';

const DataSource = ({
  activeDataSource,
  dataSources,
  defaultYearFromDeepAnalysis,
  defaultYearToDeepAnalysis,
  disableMenuselection,
  setActiveDataSource,
  handleDataSourceChange,
}) => {
  const history = useHistory();

  const [forbund, setForbund] = useState(activeDataSource.forbund);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableForbund, setAvailableForbund] = useState([]);
  const [availableDSL1, setAvailableDSL1] = useState([]);
  const [availableDSL2, setAvailableDSL2] = useState([]);

  const { user } = useAuth();
  const { role } = user;

  const yearRange =
    defaultYearFromDeepAnalysis !== 0 &&
    defaultYearFromDeepAnalysis !== null &&
    defaultYearToDeepAnalysis - defaultYearFromDeepAnalysis > 1;

  const getAvailableSources = (source, rules) =>
    source.filter(item =>
      rules.every(({ property, expression }) => expression(item[property]))
    );

  const findSource = (source, rules) =>
    source.find(item =>
      rules.every(({ property, expression }) => expression(item[property]))
    );

  const inRange = (x, min, max) => (x - min) * (x - max) <= 0;

  const getYear = p => {
    const from = defaultYearFromDeepAnalysis;
    const to = defaultYearToDeepAnalysis;

    // defaultYearFromDeepAnalysis might be null, it might also be 0 in some cases
    const fromConfigured = from !== null && from !== 0;

    
    console.log(`activeDataSource.year: ${activeDataSource.year}`);
    

    if (role === 'medlem' && fromConfigured && from !== to) {
      return inRange(p, from, to);
    }

    // If defaultYearFromDeepAnalysis is not configured members has to have the option
    // to search one year back in time, out of the configured range
    if (role === 'medlem') {
      return inRange(p, to - 1, to);
    }

    return p === activeDataSource.year;
  };

  // This function uses the findSource function recursively with a list of rules.
  // If the findSource function don't return a result for all of the defined rules
  // the function will try again with all rules except the last one.
  // const sequentiallyFindSource = (arr, rules) => {
  //   let result;
  //   console.log("sequentiallyFindSource arr:", arr);
  //   console.log("sequentiallyFindSource rules:", rules);
  //   console.table(rules);
  //   rules.forEach((rule, index) => {
  //     console.log(`Rule ${index}: property = ${rule.property}, expression = ${rule.expression.toString()}`);
  // });


  //   for (let i = 0; i < rules.length; i += 1) {
  //     result = findSource(arr, rules);
  //     if (!result) {
  //       rules.pop();
  //     }
  //     if (result) {
  //       break;
  //     }
  //   }

  //   // If no result, find the first source with the "DEFAULT" property set to 1
  //   if (!result) {
  //     result = findSource(arr, [
  //       { property: 'DEFAULT', expression: p => p === 1 },
  //     ]);
  //   }

  //   // If there's no default source configured, choose the first object in the array
  //   if (!result) [result] = arr;
  //   console.log("sequentiallyFindSource result:", result);
  //   return result;
  // };

  const sequentiallyFindSource = (arr, rules) => {
    console.log("Starting sequentiallyFindSource");
    console.log("Initial array (arr):", arr);
    console.log("Initial rules:", rules);

    // Gruppvis sortering: Behåll den övergripande ordningen men prioritera rätt val inom grupperna
    const groupedSort = arr.reduce((acc, item) => {
        const key = item.DSL1; // Gruppera på DSL1
        if (!acc[key]) acc[key] = [];
        acc[key].push(item);
        return acc;
    }, {});

    Object.keys(groupedSort).forEach(group => {
        groupedSort[group].sort((a, b) => {
            // Löner från enkät (LE) - Prioritera "Samtliga" inom denna grupp
            if (group === "LE") {
                if (a.DSL2 === "DF") return -1;
                if (b.DSL2 === "DF") return 1;
            }
            // Löner från arbetsgivare (AG) - Prioritera sektorer i rätt ordning
            if (group === "AG") {
                const priorityOrder = [
                    "Regional sektor", 
                    "Regional sektor - chefslöner",
                    "Kommunal sektor", 
                    "Kommunal sektor - chefslöner",
                    "Statlig sektor",
                    "Statlig sektor - chefslöner" // Denna ska hamna sist
                ];

                const indexA = priorityOrder.findIndex(desc => a.DSL2_DESC.includes(desc));
                const indexB = priorityOrder.findIndex(desc => b.DSL2_DESC.includes(desc));

                if (indexA !== -1 && indexB !== -1) {
                    return indexA - indexB; // Sortera baserat på index i priorityOrder
                }
            }
            return 0;
        });
    });

    // Återskapa listan i rätt ordning utan att störa andra grupper
    const sortedArr = Object.values(groupedSort).flat();

    console.log("Sorted array before applying rules:", sortedArr);

    let result;
    for (let i = 0; i < rules.length; i += 1) {
        console.log(`Iteration ${i + 1} - Applying rules:`, rules);
        result = findSource(sortedArr, rules);
        
        if (result) {
            console.log(`Found result at iteration ${i + 1}:`, result);
            break;
        } else {
            console.log(`No result found at iteration ${i + 1}. Removing last rule.`);
            rules.pop();
        }
    }

    if (!result) {
        console.log("No result found with all rules. Trying DEFAULT = 1.");
        result = findSource(sortedArr, [{ property: 'DEFAULT', expression: p => p === 1 }]);
    }

    if (!result && sortedArr.length > 0) {
        console.log("No default found, choosing first in list.");
        [result] = sortedArr;
    }

    console.log("Final result from sequentiallyFindSource:", result);
    return result;
};


  const setSource = source =>
    setActiveDataSource({
      forbund: source.FORBUND,
      dsl1: source.DSL1,
      dsl2: source.DSL2,
      measureVar: source.measure_var,
      year: source.YEAR,
    });

  const handleChange = (value, type) => {
    const previousDatasource = { ...activeDataSource };
    console.log(`handleChange value: ${value} type: ${type}`);
    if (type === 'year') {
      console.log(`handleChange value: ${value} type: ${type}`);
      handleDataSourceChange(value);

      const availableSources = getAvailableSources(dataSources, [
        { property: 'YEAR', expression: p => p === Number(value) },
      ]);

      console.log("handleChange availableSources:", availableSources);

      const newSource = sequentiallyFindSource(availableSources, [
        { property: 'DSL1', expression: p => p === previousDatasource.dsl1 },
        {
          property: 'FORBUND',
          expression: p => p === previousDatasource.forbund,
        },
        { property: 'DSL2', expression: p => p === previousDatasource.dsl2 },
      ]);
      console.log("handleChange newSource:", newSource);
      setSource(newSource);
    }


    if (type === 'forbund') {
      const availableSources = getAvailableSources(dataSources, [
          { property: 'FORBUND', expression: p => p === value },
          { property: 'YEAR', expression: p => p === activeDataSource.year },
      ]).sort((a, b) => b?.YEAR - a?.YEAR);

      console.log("Available sources for selected forbund and year:", availableSources);
      console.log("Current activeDataSource:", activeDataSource);

      // Kontrollera om den nuvarande insamlingskällan (DSL1) fortfarande är tillgänglig
      const currentDsl1Available = availableSources.some(source => source.DSL1 === activeDataSource.dsl1);
      
      console.log("Is current DSL1 available in new sources?:", currentDsl1Available);

      if (currentDsl1Available) {
          // Behåll den nuvarande insamlingskällan om den finns tillgänglig
          console.log("Behåll den nuvarande insamlingskällan availableSources", availableSources);
          console.log("Behåll den nuvarande insamlingskällan activeDataSource", activeDataSource);
          console.log("Behåll den nuvarande insamlingskällan previousDatasource", previousDatasource);
          const newSource = sequentiallyFindSource(availableSources, [
              { property: 'DSL1', expression: p => p === activeDataSource.dsl1 },
              // { property: 'DSL2', expression: p => p === activeDataSource.dsl2 },
          ]);
          console.log("Keeping current DSL1, selected new source (should be same as current):", newSource);
          setSource(newSource);
      } else {
          // Om den nuvarande insamlingskällan inte är tillgänglig, välj en standardkälla
          const newSource = sequentiallyFindSource(availableSources, [
              { property: 'DSL1', expression: p => p === previousDatasource.dsl1 },
              { property: 'DSL2', expression: p => p === previousDatasource.dsl2 },
          ]);
          console.log("Current DSL1 not available, choosing a new default source:", newSource);
          setSource(newSource);
      }

      setForbund(value);
  }


    if (type === 'dsl1') {
      
      const availableSources = getAvailableSources(dataSources, [
        {
          property: 'FORBUND',
          expression: p => p === activeDataSource.forbund,
        },
        { property: 'DSL1', expression: p => p === value },
        {
          property: 'YEAR',
          expression: p => getYear(p),
        },
      ]).sort((a, b) => {
        if (
          a.YEAR === activeDataSource.year &&
          b.YEAR !== activeDataSource.year
        ) {
          return -1;
        }
        if (
          a.YEAR !== activeDataSource.year &&
          b.YEAR === activeDataSource.year
        ) {
          return 1;
        }
        return b?.YEAR - a?.YEAR;
      });

      console.log("handleChange dsl1 availableSources:", availableSources);

      const newSource = sequentiallyFindSource(availableSources, [
        { property: 'YEAR', expression: p => p === previousDatasource.year },
        { property: 'DSL2', expression: p => p === previousDatasource.dsl2 },
      ]);

      console.log("handleChange dsl1 newSource:", newSource);

      setSource(newSource);
    }

    if (type === 'dsl2') {
      const availableSources = getAvailableSources(dataSources, [
        {
          property: 'FORBUND',
          expression: p => p === activeDataSource.forbund,
        },
        {
          property: 'YEAR',
          expression: p => getYear(p),
        },
        { property: 'DSL2', expression: p => p === value },
      ]).sort((a, b) => {
        if (
          a.YEAR === activeDataSource.year &&
          b.YEAR !== activeDataSource.year
        ) {
          return -1;
        }
        if (
          a.YEAR !== activeDataSource.year &&
          b.YEAR === activeDataSource.year
        ) {
          return 1;
        }
        return b?.YEAR - a?.YEAR;
      });

      let newSource;

      // Try an older source only if the user currently are viewing the default year
      // If not, the user should be redirected to the newest possible source
      if (role === 'medlem' && !yearRange) {
        newSource = sequentiallyFindSource(availableSources, [
          {
            property: 'YEAR',
            expression: p => {
              if (activeDataSource.year === defaultYearToDeepAnalysis) {
                return p >= defaultYearToDeepAnalysis - 1;
              }

              return p === defaultYearToDeepAnalysis;
            },
          },
        ]);
      } else {
        newSource = sequentiallyFindSource(availableSources, [
          {
            property: 'YEAR',
            expression: p => p === previousDatasource.year,
          },
        ]);
      }
      setSource(newSource);
    }
  };

  useEffect(() => {

    console.log("setAvailableForbund (before filtering):", dataSources);

    // Filtrera de förbund som har data för det valda året
    const filteredForbund = dataSources
        .filter(source => source.YEAR === activeDataSource.year) // Filtrera för valt år
        .reduce((acc, curr) => {
            if (!acc.includes(curr.FORBUND)) acc.push(curr.FORBUND);
            return acc;
        }, []);

    setAvailableForbund(filteredForbund);
    console.log("setAvailableForbund (filtered):", filteredForbund);

    setAvailableYears(
      dataSources
        .filter(
          ds =>
            ds.DSL1 === activeDataSource.dsl1 &&
            ds.DSL2 === activeDataSource.dsl2
        )
        .reduce((acc, curr) => {
          if (!acc.includes(curr.YEAR)) acc.push(curr.YEAR);
          return acc;
        }, [])
        .filter(y => {
          if (
            role === 'medlem' &&
            defaultYearFromDeepAnalysis &&
            forbund !== 'SA'
          ) {
            return inRange(
              y,
              defaultYearFromDeepAnalysis,
              defaultYearToDeepAnalysis
            );
          }

          if (role === 'medlem') {
            return inRange(
              y,
              defaultYearToDeepAnalysis - 1,
              defaultYearToDeepAnalysis
            );
          }

          return y;
        })
        .sort()
    );
  }, [
    activeDataSource,
    role,
    dataSources,
    defaultYearFromDeepAnalysis,
    defaultYearToDeepAnalysis,
    forbund,
  ]);

  useEffect(() => {
    setAvailableDSL1(
      getAvailableSources(dataSources, [
        {
          property: 'FORBUND',
          expression: p => p === activeDataSource.forbund,
        },
        {
          property: 'YEAR',
          expression: p => getYear(p),
        },
      ])
        .map(ds => {
          return {
            dsl: ds.DSL1,
            desc: ds.DSL1_DESC,
          };
        })
        .reduce((acc, curr) => {
          if (!acc.some(a => a.dsl === curr.dsl)) acc.push(curr);
          return acc;
        }, [])
    );
    // eslint-disable-next-line
  }, [
    activeDataSource.year,
    activeDataSource.forbund,
    defaultYearFromDeepAnalysis,
    defaultYearToDeepAnalysis,
    role,
  ]);

  useEffect(() => {
    setAvailableDSL2(
        getAvailableSources(dataSources, [
            {
                property: 'FORBUND',
                expression: p => p === activeDataSource.forbund,
            },
            { property: 'DSL1', expression: p => p === activeDataSource.dsl1 },
            {
                property: 'YEAR',
                expression: p => getYear(p),
            },
        ])
        .sort((a, b) => b.YEAR - a.YEAR) // Sortera efter år, senaste först
        .map(ds => {
            const lastYearsData = ds.YEAR !== activeDataSource.year;
            return {
                dsl: ds.DSL2,
                desc: lastYearsData ? `${ds.DSL2_DESC} - ${ds.YEAR}` : ds.DSL2_DESC,
                lastYearsData,
            };
        })
        .sort((a, b) => a.lastYearsData - b.lastYearsData) // Sortera så att senaste året kommer först
        .reduce((acc, curr) => {
            if (!acc.some(a => a.dsl === curr.dsl)) acc.push(curr);
            return acc;
        }, [])
        .filter(a => a.desc !== 'Okï¿½nt' && a.desc !== 'Okänt') // Filtrera bort "Okänt"
        .sort((a, b) => {
            // Prioritera "Samtliga" överst
            if (a.desc.includes("Samtliga")) return -1;
            if (b.desc.includes("Samtliga")) return 1;
            return 0;
        })
    );
    // eslint-disable-next-line
}, [
    activeDataSource.year,
    activeDataSource.dsl1,
    activeDataSource.forbund,
    defaultYearFromDeepAnalysis,
    defaultYearToDeepAnalysis,
    role,
]);


  const allAvailableYears = () =>
    availableYears.map(item => ({
      key: item,
      value: item,
      desc: item,
    }));

  // TODO: se över logiken, utför i backend och returnera en array med optionsen bara i ett API-kall
  const getYearOptions = () => {
    if (role === 'medlem') {
      if (activeDataSource.forbund === 'CF') {
        return allAvailableYears();
      }
      const latestAvailableYear = availableYears[availableYears.length - 1];

      return [
        {
          key: latestAvailableYear,
          value: latestAvailableYear,
          desc: latestAvailableYear,
        },
      ];
    }

    return allAvailableYears();
  };

  return (
    <>
      <div id="datasources" className={styles.dataSource}>
        <h2 className={styles.dataSourceHeading}>
          <IconDatabase
            className={styles.indicatorSymbol}
            role="img"
            aria-label=""
          />
          <span className={styles.buttonText}>Statistikkällor</span>
        </h2>
        <DataSourceSummary activeDataSource={activeDataSource} />

        {/* Dont let users change year, unless there's an interval configured */}
        {/* That is: defaultYearFromDeepAnalysis can't be null, zero or just defaultYearToDeepAnalysis - 1  */}
        {(role !== 'medlem' || (role === 'medlem' && yearRange)) && (
          <div className={styles.dataSourceSelection}>
            <Select
              label="År"
              disabled={disableMenuselection}
              id="ar_select"
              options={getYearOptions()}
              onChange={evt => handleChange(evt.target.value, 'year')}
              value={activeDataSource.year.toString()}
            />
          </div>
        )}

        <div className={styles.dataSourceSelection}>
          <Select
            label="Statistikkälla"
            disabled={disableMenuselection} // || availableForbund.length < 2
            id="forbund_select"
            onChange={evt => handleChange(evt.target.value, 'forbund')}
            options={availableForbund.map(item => ({
              key: item,
              value: item,
              desc: item === 'SA' ? 'Samtliga Sacoförbund' : 'Mitt förbund',
            }))}
            value={activeDataSource.forbund}
          />
        </div>
      
        <div className={styles.dataSourceSelection}>
          <Select
            label="Insamlingskälla"
            disabled={disableMenuselection} // || availableDSL1.length < 2
            id="dsl1_select"
            onChange={evt => handleChange(evt.target.value, 'dsl1')}
            options={availableDSL1.map(({ desc, dsl }) => ({
              key: dsl,
              value: dsl,
              desc,
            }))}
            value={activeDataSource.dsl1}
          />
        </div>
        {availableDSL2.length > 0 && (
          <div className={styles.dataSourceSelection}>
            <Select
              label="Alternativ"
              disabled={disableMenuselection}
              id="dsl2_select"
              onChange={evt => handleChange(evt.target.value, 'dsl2')}
              options={availableDSL2.map(({ desc, dsl }) => ({
                key: dsl,
                value: dsl,
                desc,
              }))}
              value={activeDataSource.dsl2}
            />
          </div>
        )}
        
        <button
          type="button"
          className={styles.infoLink}
          onClick={() => history.push('#statistics')}
        >
          Läs om statistikkällorna
        </button>
      </div>
      <div className={styles.print}>
        <DataSourceSummary
          activeDataSource={activeDataSource}
          availableDSL1={availableDSL1}
          availableDSL2={availableDSL2}
        />
      </div>
    </>
  );
};

DataSource.propTypes = {
  activeDataSource: PropTypes.shape({
    forbund: PropTypes.string.isRequired,
    dsl1: PropTypes.string.isRequired,
    dsl2: PropTypes.string.isRequired,
    measureVar: PropTypes.arrayOf(String).isRequired,
    year: PropTypes.number.isRequired,
  }).isRequired,
  dataSources: PropTypes.arrayOf(Object).isRequired,
  defaultYearFromDeepAnalysis: PropTypes.number,
  defaultYearToDeepAnalysis: PropTypes.number.isRequired,
  disableMenuselection: PropTypes.bool.isRequired,
  setActiveDataSource: PropTypes.func.isRequired,
  handleDataSourceChange: PropTypes.func.isRequired,
};

DataSource.defaultProps = {
  defaultYearFromDeepAnalysis: null,
};

export default DataSource;
