import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './PageErrorBoundary.module.css';
import Logo from '../../testdata/saco-lonesok_logotype.svg';

class PageErrorBoundary extends Component {
  state = { hasError: false, error: '' };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.handleAsyncError);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handleAsyncError);
  }

  handleAsyncError = event => {
    console.error('Async error caught in PageErrorBoundary:', event.reason);
  
    const errorMessage =
      event.reason && event.reason.message
        ? event.reason.message
        : 'Ett okänt fel inträffade.';
  
    this.setState({ hasError: true, error: errorMessage });
  };
  

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.main}>
          <div className={styles.errorBox}>
            <h1 className={styles.header}>Något har gått fel!</h1>
            <p className={styles.text}>
              Tyvärr verkar Saco Lönesök inte vara tillgängligt för tillfället. Testa gärna lite senare.
            </p>
            <p className={styles.text}>
              Är det bråttom? För frågor om lön, lönerådgivning eller statistik, kontakta ditt förbund.
            </p>
            <p className={styles.text}>
              Har du tekniska synpunkter eller frågor? Hör av dig till Saco på{' '}
              <a href="mailto:lonesok@saco.se" className={styles.link}>
                lonesok@saco.se
              </a>{' '}
              och ange gärna vilket förbund du tillhör.
            </p>
            <p className={styles.text}>Tack för ditt tålamod!</p>
            <div className={styles.footer}>
              <img className={styles.logo} src={Logo} alt="SACO Lönesök, Sveriges Akademiker" />
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(PageErrorBoundary);
